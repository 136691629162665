import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { TopBarLayout } from '@layouts'
import { Box } from '@components'
import Modal from 'react-modal'
import { GetRequestsResponseItem } from '@/apis/requests'
import { useRequests } from '@hooks'
import { Button, Form, Row, Input } from 'antd'
import { useLocation } from 'react-router-dom'

const SpinWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
`

const StyledBox = styled(Box)`
    position: relative;
    align-items: flex-start;
`

const MessageContainer = styled.div<{ isSender: boolean }>`
    display: flex;
    justify-content: ${({ isSender }) =>
        isSender ? 'flex-end' : 'flex-start'};
    padding: 10px;
`

const MessageBubble = styled.div<{ isSender: boolean }>`
    max-width: 90%;
    min-width: 90%;
    padding: 10px;
    border-radius: 10px;
    background-color: ${({ isSender }) => (isSender ? '#DCF8C6' : '#FFF')};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    position: relative;
`

const SenderNameStyle = styled.div`
    font-size: 0.85em;
    color: #555;
    margin-bottom: 5px;
`

const MessageTextStyle = styled.div`
    font-size: 1em;
    color: #000;
`

const SenderIdStyle = styled.div`
    font-size: 0.75em;
    color: #aaa;
    margin-top: 5px;
    text-align: right;
`

const OneArchivedRequest: React.FC = () => {
    const location = useLocation()
    const requestData = location.state?.data as GetRequestsResponseItem
    const { TextArea } = Input

    const [oneRequest, setOneRequest] = useState<GetRequestsResponseItem>()
    const [chatComment, setChatComment] = useState<string | null>(null)

    const {
        updateRequest: { updateRequestV2 },
    } = useRequests()

    useEffect(() => {
        if (!requestData) return
        setChatComment(requestData.comment)
        setOneRequest(requestData)
    }, [requestData])

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const openModal = () => {
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    const dateStringToLocale = (dateString: string) => {
        const date = new Date(dateString)
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    }

    const typeToString = (type: string) => {
        switch (type) {
            case 'improvement':
                return 'Предложение улучшения'
            case 'problem':
                return 'Сообщение о проблеме'
            case 'book':
                return 'Заказ книги'
            default:
                return 'Предложение улучшения'
        }
    }

    const renderHistory = (history: GetRequestsResponseItem['history']) => {
        return history!.map((item, index) => (
            <MessageContainer key={index} isSender={true}>
                <MessageBubble isSender={false}>
                    <SenderNameStyle>
                        {item.status === 'new'
                            ? 'Заявка создана'
                            : 'Статус изменен'}
                    </SenderNameStyle>
                    <MessageTextStyle>{item.description}</MessageTextStyle>
                    <SenderIdStyle>
                        {dateStringToLocale(item.createdat)}
                    </SenderIdStyle>
                </MessageBubble>
            </MessageContainer>
        ))
    }

    function updateChatComment() {
        if (!oneRequest?.id) return

        updateRequestV2({ id: oneRequest.id, comment: chatComment })

        // update local data
        setOneRequest({ ...oneRequest, comment: chatComment })
    }

    return (
        <TopBarLayout
            title={typeToString(oneRequest?.type ?? 'Заявка от сотрудника')}
            withGoBack
        >
            <StyledBox>
                {oneRequest ? (
                    <>
                        <div>
                            {oneRequest.photo_filename ? (
                                <Form>
                                    <div
                                        style={{
                                            width: '250px',
                                            marginBottom: '24px',
                                        }}
                                    >
                                        <div>
                                            <img
                                                src={`https://api.startups-dna.com/images/${oneRequest.photo_filename}`}
                                                alt="Request"
                                                style={{
                                                    width: '100%',
                                                    maxHeight: '500px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={openModal}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            ) : (
                                <Form>
                                    <div
                                        style={{
                                            width: '250px',
                                            marginBottom: '24px',
                                        }}
                                    >
                                        <img
                                            src={`https://placehold.co/600x400?text=Нет+фото`}
                                            alt="Request"
                                            style={{
                                                width: '100%',
                                                maxHeight: '500px',
                                            }}
                                        />
                                    </div>
                                </Form>
                            )}

                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                contentLabel="Image Modal"
                                style={{
                                    content: {
                                        top: '50%',
                                        left: '50%',
                                        right: 'auto',
                                        bottom: 'auto',
                                        marginRight: '-50%',
                                        transform: 'translate(-50%, -50%)',
                                    },
                                }}
                            >
                                <img
                                    src={`https://api.startups-dna.com/images/${oneRequest.photo_filename}`}
                                    alt="Request"
                                    style={{
                                        width: '100%',
                                        maxHeight: '80vh',
                                    }}
                                />
                                <Button
                                    onClick={closeModal}
                                    style={{
                                        display: 'block',
                                        marginTop: '10px',
                                    }}
                                >
                                    Закрыть
                                </Button>
                            </Modal>
                        </div>

                        <Form
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            autoComplete="off"
                            layout="vertical"
                            style={{ width: '500px', margin: '0 auto' }}
                        >
                            <div
                                style={{
                                    width: '500px',
                                    marginBottom: '24px',
                                }}
                            >
                                <h3>Описание заявки</h3>
                                <MessageContainer isSender={true}>
                                    <MessageBubble isSender={true}>
                                        <MessageTextStyle>
                                            {oneRequest.text}
                                        </MessageTextStyle>
                                    </MessageBubble>
                                </MessageContainer>
                            </div>

                            <div
                                style={{
                                    width: '500px',
                                    marginBottom: '24px',
                                }}
                            >
                                <h3>История</h3>
                                {renderHistory(oneRequest.history)}
                            </div>
                        </Form>

                        <Form>
                            <div
                                style={{
                                    width: '300px',
                                    marginBottom: '24px',
                                }}
                            >
                                <b>Дата создания заявки</b>
                                <div style={{ width: '300px' }}>
                                    {dateStringToLocale(oneRequest.createdat)}
                                </div>
                            </div>

                            <div style={{ marginBottom: '24px' }}>
                                <b>Сотрудник</b>
                                <Row align="top">
                                    {oneRequest.staff.photo && (
                                        <img
                                            src={`https://api.startups-dna.com/images/${oneRequest.staff.photo}`}
                                            alt="Request"
                                            style={{
                                                width: '100%',
                                                maxHeight: '30px',
                                                maxWidth: '30px',
                                                marginRight: '8px',
                                                marginTop: '8px',
                                            }}
                                        />
                                    )}
                                    <div
                                        style={{
                                            width: '300px',
                                            marginTop: '8px',
                                        }}
                                    >
                                        {oneRequest.staff.full_name}
                                    </div>
                                </Row>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '230px',
                                    marginTop: '18px',
                                }}
                            >
                                <b>Добавить комментарий</b>
                                <TextArea
                                    style={{ marginTop: '15px' }}
                                    rows={4}
                                    placeholder="Добавить комментарий"
                                    value={chatComment || undefined}
                                    onChange={(e) =>
                                        setChatComment(e.target.value || null)
                                    }
                                ></TextArea>
                                {oneRequest?.comment !== chatComment && (
                                    <Button onClick={updateChatComment}>
                                        send
                                    </Button>
                                )}
                            </div>
                        </Form>
                    </>
                ) : (
                    <SpinWrapper>Нет данных для отображения</SpinWrapper>
                )}
            </StyledBox>
        </TopBarLayout>
    )
}

export default OneArchivedRequest
