import { SET_MESSAGE } from '@/redux/message'
import { useAppDispatch } from '@/redux/hook'
import { UpdateChatMessage } from '@apis/chat'
import { useRestriction } from './useRestriction'
import Apis from '@apis'

export function useChat() {
    const dispatch = useAppDispatch()
    const { isAuth } = useRestriction()

    async function updateChatMessage(body: UpdateChatMessage) {
        if (!isAuth) return

        try {
            const { data } = await Apis.chat.editChatMessage(body)
            return data.data
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Не удалось загрузить',
                    type: 'error',
                })
            )
        }
    }

    async function deleteChatMessage(id: number) {
        if (!isAuth) return

        try {
            const { data } = await Apis.chat.deleteChatMessage(id)
            return data.data
        } catch (e) {
            dispatch(
                SET_MESSAGE({
                    text: 'Не удалось загрузить',
                    type: 'error',
                })
            )
        }
    }

    return {
        updateChatMessage,
        deleteChatMessage,
    }
}
