import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@/redux/hook'
import { Button, Table } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import type { TableProps } from 'antd'
import { useRequests } from '@hooks'
import { GetRequestsResponseItem } from '@apis/requests'
import { TopBarLayout } from '@layouts'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Link, useNavigate } from 'react-router-dom'
import { Box } from '@/components'

const statusToString = (status: string) => {
    switch (status) {
        case 'new':
            return 'Новая'
        case 'in_progress':
            return 'В работе'
        case 'solved':
            return 'Решена'
        case 'rejected':
            return 'Отклонена'
        default:
            return ''
    }
}

const typeToString = (type: string) => {
    switch (type) {
        case 'impruvement':
            return 'Предложение'
        case 'problem':
            return 'Проблема'
        case 'book':
            return 'Книга'
        default:
            return ''
    }
}

const calculateTimePassed = (createdat: string) => {
    const now = new Date().getTime()
    const createdTime = new Date(createdat).getTime() - 5 * 60 * 60 * 1000

    if (isNaN(createdTime)) {
        return 'Неверная дата'
    }

    let diff = now - createdTime

    const days = Math.floor(diff / (1000 * 60 * 60 * 24))
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((diff / (1000 * 60)) % 60)

    let timeString = ''

    if (days > 0) {
        timeString += `${days} дн `
    }

    if (hours > 0) {
        timeString += `${hours} ч `
    }

    timeString += `${minutes} мин`

    return timeString.trim()
}

const columns: TableProps<GetRequestsResponseItem>['columns'] = [
    {
        title: '№',
        dataIndex: 'index',
        render: (_: any, __: any, index: number) => index + 1,
        width: '2%',
        align: 'center',
    },
    {
        title: 'Дата создания',
        dataIndex: 'createdat',
        render: (createdat) => {
            const createdDate = new Date(createdat)
            const correctedTime = new Date(
                createdDate.getTime() - 5 * 60 * 60 * 1000
            )
            return correctedTime.toLocaleString('ru-RU').substring(0, 17)
        },
        sorter: (a, b) =>
            new Date(a.createdat).getTime() - new Date(b.createdat).getTime(),
        defaultSortOrder: 'descend',
        width: '10%',
        align: 'center',
    },
    {
        title: 'Времени прошло',
        dataIndex: 'createdat',
        render: calculateTimePassed,
        width: '10%',
        align: 'center',
    },
    {
        title: 'Текст',
        dataIndex: 'text',
        render: (text) => text,
        sorter: (a, b) => a.text.localeCompare(b.text),
        width: 80,
    },
    {
        title: 'Тип',
        dataIndex: 'type',
        render: (type) => typeToString(type),
        sorter: (a, b) => a.type!.localeCompare(b.type!),
        width: '10%',
        align: 'center',
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        render: (status) => statusToString(status),
        sorter: (a, b) => a.status.localeCompare(b.status),
        width: '10%',
        align: 'center',
    },
    {
        title: 'Сотрудник',
        dataIndex: 'staff',
        render: (staff) => staff.full_name,
        sorter: (a, b) =>
            (a.staff.full_name || '')
                .toString()
                .localeCompare(b.staff.full_name || ''),
        width: '13%',
        align: 'center',
    },
    {
        title: 'Удалить',
        render: (record) => <DeleteRequest id={record.id} />,
        width: '10%',
        align: 'center',
    },
]

function DeleteRequest({ id }: { id: number }) {
    const {
        deleteRequest: { deleteRequest },
    } = useRequests()

    function requestDelete(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        e.stopPropagation()
        deleteRequest(id, 'hard')
    }

    return (
        <Button
            type="primary"
            icon={<DeleteFilled />}
            danger
            onClick={requestDelete}
        />
    )
}

const Requests: React.FC = () => {
    let {
        items,
        loading,
        count,
        filters: { page, limit, search },
    } = useAppSelector((state) => state.requests)
    const nav = useNavigate()
    const {
        getRequests: { getRequests },
    } = useRequests()

    const [searchValue, setSearchValue] = useState(search || '')

    const onSearch = async () => {
        const search = searchValue.trim()
        if (search) {
            await getRequests({ page: 1, search })
        } else {
            await getRequests({ page: 1, search: undefined })
        }
    }

    useEffect(() => {
        getRequests({ page: 1, limit })
        // eslint-disable-next-line
    }, [])

    return (
        <TopBarLayout
            title="Открытые заявки"
            withSearchBox
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={onSearch}
            postfix={
                <Box style={{ gap: '20px' }}>
                    <Link to="requests/archive">
                        <Button>Закрытые заявки</Button>
                    </Link>
                </Box>
            }
        >
            <AutoSizer style={{ width: '100%' }}>
                {({ width, height }: any) => (
                    <Table<GetRequestsResponseItem>
                        style={{ width: width + 'px' }}
                        dataSource={items}
                        columns={columns}
                        rowKey="id"
                        loading={loading}
                        pagination={{
                            total: count,
                            pageSize: limit,
                            hideOnSinglePage: true,
                            onChange: (page) => getRequests({ page }),
                            current: page,
                        }}
                        onRow={(data) => ({
                            onClick: () => nav(`/requests/${data.id}`),
                        })}
                        scroll={{ x: 1400 }}
                    />
                )}
            </AutoSizer>
        </TopBarLayout>
    )
}

export default Requests
