import React, { useEffect, useState } from 'react'
import { Button, Menu, MenuProps } from 'antd'
import { Navigate, NavLink, Route, Routes, useLocation } from 'react-router-dom'
import {
    Admins,
    Guests,
    OneStaff,
    Staff,
    StaffArchive,
    Regulations,
    Readme,
    Books,
    OneBook,
    Wall,
    CreateWall,
    Requests,
} from '@/screens'
import styled, { keyframes } from 'styled-components'
import { PrivateRoute, SettingsRoutes } from '.'
import { FiUsers, FiSettings, FiMail, FiList } from 'react-icons/fi'
import { FaUsers } from 'react-icons/fa'
import { GrUserAdmin } from 'react-icons/gr'
import { useAuth } from '@hooks'
import { DinnerReport, Dinners, OneDinner, StaffDinner } from '@screens/dinners'
import { GiMeal } from 'react-icons/gi'
import { TbHandStop } from 'react-icons/tb'
import { SiAboutdotme } from 'react-icons/si'
import { ImBooks } from 'react-icons/im'
import { OneRequest } from '@/screens/requests'
import RequestsArchive from '@/screens/requests/requestsArchive'
import OneArchivedRequest from '@/screens/requests/oneArchivedRequest'
import GetOneWall from '@/screens/wall/oneWall'

interface MenuWrapperProps {
    isOpen: boolean
}

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: 50px 1fr;
    grid-template-areas: 'title title' 'menu content';
    height: inherit;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
    }
`

const Overlay = styled.div<MenuWrapperProps>`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
`
const MenuWrapper = styled.div<MenuWrapperProps>`
    top: 0;
    left: 0;
    grid-area: menu;
    display: flex;
    flex-direction: column;
    background-color: white;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 10;

    @media (max-width: 768px) {
        animation: ${({ isOpen }) => (isOpen ? slideIn : slideOut)} 0.3s
            forwards;
        position: fixed;
    }

    @media (min-width: 769px) {
        position: relative;
        animation: none;
    }
`

const Title = styled.h1`
    margin: 0;
`

const Box = styled.div`
    padding: 6px 20px 6px 28px;
    grid-area: title;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
        padding: 6px;
    }
`

const Content = styled.div`
    grid-area: content;
    padding: 20px;
    height: inherit;
    overflow-y: auto;

    @media (max-width: 768px) {
        padding: 10px;
    }
`

const MenuToggle = styled.button`
    display: none;

    @media (max-width: 768px) {
        display: block;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
    }
`

export const DashboardRoutes: React.FC = () => {
    const [selectedKeys, setSelectedKeys] = useState(['requests'])
    const [menuOpen, setMenuOpen] = useState(false)
    const location = useLocation()
    const {
        logOut: { logOut, logOutLoading },
    } = useAuth()

    useEffect(() => {
        if (location.pathname === '/') {
            setSelectedKeys(['requests'])
        } else {
            const rootLocation = location.pathname.split('/')[1]
            setSelectedKeys([rootLocation])
        }
    }, [location])

    const items: MenuProps['items'] = [
        {
            label: <NavLink to="/">Заявки</NavLink>,
            key: 'requests',
            icon: <FiMail size="20" />,
        },
        {
            label: <NavLink to="/staff">Сотрудники</NavLink>,
            key: 'staff',
            icon: <FiUsers size="20" />,
        },
        {
            label: <NavLink to="/guests">Гости</NavLink>,
            key: 'guests',
            icon: <FaUsers size="20" />,
        },
        {
            label: <NavLink to="/admins">Админы</NavLink>,
            key: 'admins',
            icon: <GrUserAdmin size="20" />,
        },
        {
            label: <NavLink to="/regulations">Регламент</NavLink>,
            key: 'regulations',
            icon: <TbHandStop size="20" />,
        },
        {
            label: <NavLink to="/dinners">Обеды</NavLink>,
            key: 'dinners',
            icon: <GiMeal size="20" />,
        },
        {
            label: <NavLink to="/books">Книги</NavLink>,
            key: 'books',
            icon: <ImBooks size="20" />,
        },
        {
            label: <NavLink to="/settings">Настройки</NavLink>,
            key: 'settings',
            icon: <FiSettings size="20" />,
        },
        {
            label: <NavLink to="/readme">Справка</NavLink>,
            key: 'readme',
            icon: <SiAboutdotme size="20" />,
        },
        {
            label: <NavLink to="/wall">Лента</NavLink>,
            key: 'wall',
            icon: <FiList size="20" />,
        },
    ]

    const handleClose = () => {
        setMenuOpen(false)
    }

    return (
        <Wrapper>
            <Box>
                <Title>Dashboard</Title>
                <Button danger onClick={logOut} loading={logOutLoading}>
                    Выйти
                </Button>
                <MenuToggle onClick={() => setMenuOpen(!menuOpen)}>
                    &#9776;
                </MenuToggle>
            </Box>
            <Overlay isOpen={menuOpen} onClick={handleClose} />
            <MenuWrapper isOpen={menuOpen}>
                <Menu items={items} selectedKeys={selectedKeys} mode="inline" />
            </MenuWrapper>
            <Content>
                <Routes>
                    <Route path="/" element={<PrivateRoute />}>
                        <Route path="">
                            <Route path="" element={<Requests />} />
                            <Route
                                path="requests/:id"
                                element={<OneRequest />}
                            />
                            <Route
                                path="requests/archive"
                                element={<RequestsArchive />}
                            />
                            <Route
                                path="requests/archive/:id"
                                element={<OneArchivedRequest />}
                            />
                        </Route>

                        <Route path="staff">
                            <Route path="" element={<Staff />} />
                            <Route path=":id" element={<OneStaff />} />
                            <Route path="archive" element={<StaffArchive />} />
                        </Route>

                        <Route path="admins" element={<Admins />} />

                        <Route path="guests" element={<Guests />} />

                        <Route path="regulations" element={<Regulations />} />

                        <Route path="books">
                            <Route path="" element={<Books />} />
                            <Route path=":id" element={<OneBook />} />
                        </Route>

                        <Route path="settings/*" element={<SettingsRoutes />} />

                        <Route path="dinners">
                            <Route path="" element={<Dinners />} />
                            <Route path=":id">
                                <Route path="" element={<OneDinner />} />
                                <Route path="staff" element={<StaffDinner />} />
                                <Route
                                    path="report"
                                    element={<DinnerReport />}
                                />
                            </Route>
                        </Route>

                        <Route path="/readme" element={<Readme />} />

                        <Route path="/wall">
                            <Route path="" element={<Wall />} />
                            <Route path="new" element={<CreateWall />} />{' '}
                            {/* Новый маршрут */}
                            <Route path=":id" element={<GetOneWall />} />
                        </Route>

                        <Route path="*" element={<Navigate to="/404" />} />
                    </Route>
                </Routes>
            </Content>
        </Wrapper>
    )
}
