import httpService from '../httpService'
import { ChatMessage, UpdateChatMessage } from './types'

export default {
    editChatMessage(body: UpdateChatMessage) {
        return httpService.patch<{ data: ChatMessage }>(
            '/dashboard/chat/chat-message-one',
            body
        )
    },
    deleteChatMessage(id: number) {
        return httpService.delete<{ data: ChatMessage }>(
            `/dashboard/chat/chat-message-one/${id}`
        )
    },
}
