import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@/redux/hook'
import { Button, Table, Popconfirm } from 'antd'
import type { TableProps } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import { useRequests } from '@hooks'
import { GetRequestsResponseItem } from '@apis/requests'
import { TopBarLayout } from '@layouts'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FaTrashRestore } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const typeToString = (type: string) => {
    switch (type) {
        case 'impruvement':
            return 'Предложение'
        case 'problem':
            return 'Проблема'
        case 'book':
            return 'Книга'
        default:
            return ''
    }
}

const RequestsArchive: React.FC = () => {
    const {
        items,
        loading,
        count,
        filters: { page, limit, search },
    } = useAppSelector((state) => state.requests)
    const {
        getArchivedRequests: { getArchivedRequests },
        unarchiveRequest: { unarchiveRequest },
    } = useRequests()

    const nav = useNavigate()
    const [searchValue, setSearchValue] = useState(search || '')

    const onSearch = async () => {
        const search = searchValue.trim()
        if (search) {
            await getArchivedRequests({ page: 1, search })
        } else {
            await getArchivedRequests({ page: 1, search: undefined })
        }
    }

    useEffect(() => {
        getArchivedRequests({ page: 1, limit })
        // eslint-disable-next-line
    }, [])

    const getColumns = () => {
        const newColumns: TableProps<GetRequestsResponseItem>['columns'] = [
            {
                title: '№',
                dataIndex: 'index',
                render: (_: any, __: any, index: number) => {
                    return (page - 1) * limit + index + 1
                },
                width: '2%',
                align: 'center',
            },
            {
                title: 'Дата создания',
                dataIndex: 'createdat',
                render: (createdat) => {
                    const createdDate = new Date(createdat)
                    const correctedTime = new Date(
                        createdDate.getTime() - 5 * 60 * 60 * 1000
                    )
                    return correctedTime
                        .toLocaleString('ru-RU')
                        .substring(0, 17)
                },
                sorter: (a, b) =>
                    new Date(a.createdat).getTime() -
                    new Date(b.createdat).getTime(),
                defaultSortOrder: 'descend',
                width: '15%',
                align: 'center',
            },
            {
                title: 'Текст',
                dataIndex: 'text',
                render: (text) => text,
                sorter: (a, b) => a.text.localeCompare(b.text),
                width: 80,
            },
            {
                title: 'Тип',
                dataIndex: 'type',
                render: (type) => typeToString(type),
                sorter: (a, b) => a.type!.localeCompare(b.type!),
                width: '10%',
                align: 'center',
            },
            {
                title: 'Сотрудник',
                dataIndex: 'staff',
                render: (staff) => staff.full_name,
                sorter: (a, b) =>
                    (a.staff.full_name || '')
                        .toString()
                        .localeCompare(b.staff.full_name || ''),
                width: '15%',
            },
            {
                title: <FaTrashRestore size="20px" />,
                render: (_, { id }, index) => (
                    <Popconfirm
                        title="Восстановление заявки"
                        description="Вы хотите восстановить эту заявку?"
                        okText="Да"
                        cancelText="Нет"
                    >
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                unarchiveRequest(id)
                            }}
                        >
                            Восстановить
                        </Button>
                    </Popconfirm>
                ),
                width: '10%',
                align: 'center',
            },
            {
                title: 'Удалить',
                render: (record) => <DeleteRequest id={record.id} />,
                width: '10%',
                align: 'center',
            },
        ]

        function DeleteRequest({ id }: { id: number }) {
            const {
                deleteRequest: { deleteRequest },
            } = useRequests()

            function requestDelete(
                e: React.MouseEvent<HTMLElement, MouseEvent>
            ) {
                e.stopPropagation()
                deleteRequest(id, 'hard')
            }

            return (
                <Button
                    type="primary"
                    icon={<DeleteFilled />}
                    danger
                    onClick={requestDelete}
                />
            )
        }

        return newColumns
    }

    return (
        <TopBarLayout
            title="Закрытые заявки"
            withGoBack
            withSearchBox
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={onSearch}
        >
            <AutoSizer style={{ width: '100%' }}>
                {({ width, height }: any) => (
                    <Table<GetRequestsResponseItem>
                        style={{ width: width + 'px' }}
                        dataSource={items}
                        columns={getColumns()}
                        rowKey="id"
                        loading={loading}
                        pagination={{
                            total: count,
                            pageSize: limit,
                            hideOnSinglePage: true,
                            onChange: (page, pageSize) =>
                                getArchivedRequests({ page, limit: pageSize }),
                            current: page,
                        }}
                        onRow={(data) => ({
                            onClick: () => {
                                nav(`/requests/archive/${data.id}`, {
                                    state: { data },
                                })
                            },
                        })}
                        scroll={{ x: 1000 }}
                    />
                )}
            </AutoSizer>
        </TopBarLayout>
    )
}

export default RequestsArchive
