import httpService from '../httpService'
import {
    CreateRequestProps,
    CreateRequestResponse,
    GetOneRequestResponse,
    GetRequestsProps,
    GetRequestsResponse,
    UpdateRequestAvatarProps,
    UpdateRequestAvatarResponse,
    UpdateRequestProps,
    UpdateRequestResponse,
    RestoreRequestResponse,
    UpdateRequest,
} from './types'

export default {
    getRequests({ params }: GetRequestsProps) {
        return httpService.get<GetRequestsResponse>('/dashboard/requests', {
            params,
        })
    },
    getArchived({ params }: GetRequestsProps) {
        return httpService.get<GetRequestsResponse>(
            '/dashboard/requests/archived',
            { params }
        )
    },
    unarchive(id: number) {
        return httpService.get<null>(`/dashboard/requests/unarchive/${id}`)
    },
    createRequest({ body }: CreateRequestProps) {
        return httpService.post<CreateRequestResponse>(
            '/dashboard/requests',
            body
        )
    },
    getOneRequest(id: number) {
        return httpService.get<GetOneRequestResponse>(
            `/dashboard/requests/${id}`
        )
    },
    updateRequest({ body, id }: UpdateRequestProps) {
        return httpService.put<UpdateRequestResponse>(
            `/dashboard/requests/${id}`,
            body
        )
    },
    updateRequestV2(body: UpdateRequest) {
        return httpService.patch<UpdateRequestResponse>(
            '/dashboard/requests',
            body
        )
    },
    updateRequestAvatar({ body, id }: UpdateRequestAvatarProps) {
        return httpService.patch<UpdateRequestAvatarResponse>(
            `/dashboard/requests/${id}/image`,
            body
        )
    },
    deleteRequest(id: number) {
        return httpService.delete<null>(`/dashboard/requests/${id}`)
    },
    deleteRequestHard(id: number) {
        return httpService.delete<null>(`/dashboard/requests/hard/${id}`)
    },
    restoreRequest(id: number) {
        return httpService.patch<RestoreRequestResponse>(
            `/dashboard/requests/${id}/restore`
        )
    },
}
