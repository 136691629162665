import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Popconfirm, Row, Select, Spin } from 'antd'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { GetRequestsResponseItem, UpdateRequestProps } from '@apis/requests'
import { ChatMessage } from '@apis/chat'
import { useRequests, useChat } from '@hooks'
import { TopBarLayout } from '@layouts'
import { Box } from '@components'
import Modal from 'react-modal'
import { Modal as AntModel } from 'antd'

const SpinWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
`

const StyledBox = styled(Box)`
    position: relative;
    align-items: flex-start;
`

const OneRequest: React.FC = () => {
    const { id } = useParams()
    const {
        getOneRequest: {
            getOneRequest,
            getOneRequestLoading,
            oneRequest,
            setOneRequest,
        },
        updateRequest: { updateRequest, updateRequestLoading },
        deleteRequest: { deleteRequest, deleteRequestLoading },
    } = useRequests()

    const { updateChatMessage, deleteChatMessage } = useChat()

    const [chatOne, setChatOne] = useState<ChatMessage | null>()
    const [form] = Form.useForm()
    const [description, setComment] = useState('')
    const [status, setStatus] = useState('')
    const [initialStatus, setInitialStatus] = useState('')
    const [initialDescription, setInitialDescription] = useState('')

    useEffect(() => {
        if (id) {
            // eslint-disable-next-line
            getOneRequest(+id)
        }
    }, [id])

    useEffect(() => {
        if (oneRequest) {
            setStatus(oneRequest.status)
            setInitialStatus(oneRequest.status)
            setInitialDescription('')
            form.setFieldsValue({
                status: oneRequest.status,
            })
        }
    }, [oneRequest, form])

    const onUpdateRequest = async (values: UpdateRequestProps['body']) => {
        if (id) {
            const updatedRequest = {
                ...values,
                id: +id,
                history: [
                    ...(oneRequest?.history ?? []),
                    {
                        status: values.status,
                        createdat: new Date().toISOString(),
                        description: description,
                    },
                ],
            }
            await updateRequest(updatedRequest)
            setComment('')
            getOneRequest(+id)
        }
    }

    const onDeleteRequest = async () => {
        if (id && oneRequest) {
            const updatedRequest = {
                ...oneRequest,
                status: 'solved',
                photo_filename: oneRequest.photo_filename ?? undefined,
                history: [
                    ...(oneRequest.history ?? []),
                    {
                        status: 'solved',
                        createdat: new Date().toISOString(),
                        description: description,
                    },
                ],
            }
            await updateRequest(updatedRequest)
            await deleteRequest(+id)
        }
    }

    const dateStringToLocale = (dateString: string) => {
        const date = new Date(dateString)
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    }

    const typeToString = (type: string) => {
        switch (type) {
            case 'improvement':
                return 'Предложение улучшения'
            case 'problem':
                return 'Сообщение о проблеме'
            case 'book':
                return 'Заказ книги'
            default:
                return 'Предложение улучшения'
        }
    }

    const [modalIsOpen, setModalIsOpen] = useState(false)

    const openModal = () => {
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    const isSaveButtonDisabled = () => {
        return (
            (status === initialStatus && description === initialDescription) ||
            (status === 'rejected' && !description)
        )
    }

    function EditModal() {
        const [chatText, setChatText] = useState<string>(chatOne?.text || '')

        function clearAndClose() {
            setChatOne(null)
            setChatText('')
        }

        async function editOnOk() {
            if (chatOne?.id && id) {
                const text = '[Startup Studio] ' + chatText
                await updateChatMessage({ id: chatOne.id, text })

                // Update old data from local
                setOneRequest((prev) => {
                    if (!prev?.chat?.length) return prev

                    prev.chat = prev.chat.map((chat) => {
                        if (chat.id === chatOne.id) chat.text = text

                        return chat
                    })

                    return prev
                })
                clearAndClose()
            }
        }

        const { TextArea } = Input

        return (
            <AntModel open={!!chatOne} onCancel={clearAndClose} onOk={editOnOk}>
                <h3>Редактировать комментарий</h3>

                <TextArea
                    rows={5}
                    value={chatText}
                    onChange={(e) => setChatText(e.target.value)}
                ></TextArea>
            </AntModel>
        )
    }

    async function deleteChatMessageOne(id: number) {
        await deleteChatMessage(id)

        // Delete old data from local
        setOneRequest((prev) => {
            if (!prev?.chat?.length) return prev

            prev.chat = prev.chat.filter((chat) => chat.id !== id)
            return { ...prev }
        })
    }

    function chat({ oneRequest }: { oneRequest: GetRequestsResponseItem }) {
        return oneRequest.chat?.map((item, index) => {
            const formattedText = item.text
                ?.replace('[Startup Studio]', '')
                .split('\n')
                .map((text, i) => (
                    <React.Fragment key={i}>
                        {text}
                        <br />
                    </React.Fragment>
                ))

            const isSender = oneRequest.staff.id === item.sender_id
            const senderName = isSender
                ? oneRequest.staff.full_name
                : 'Startup Studio'

            return (
                <div key={item.id} style={messageContainer(isSender)}>
                    <div style={messageBubble(isSender)}>
                        <div style={senderNameStyle}>
                            <div>{senderName}</div>
                            {!isSender && (
                                <div>
                                    <button
                                        title="edit"
                                        style={editDeleteBtnStyle}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const oldText = item.text.replace(
                                                '[Startup Studio] ',
                                                ''
                                            )
                                            item.text = oldText
                                            setChatOne(item)
                                        }}
                                    >
                                        &#9998;
                                    </button>
                                    <button
                                        title="delete"
                                        style={editDeleteBtnStyle}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            deleteChatMessageOne(item.id)
                                        }}
                                    >
                                        &#10005;
                                    </button>
                                </div>
                            )}
                        </div>
                        <div style={messageTextStyle}>{formattedText}</div>
                        <div style={senderIdStyle}>
                            {dateStringToLocale(item.updated_at)}
                        </div>
                    </div>
                </div>
            )
        })
    }

    const messageContainer = (isSender: boolean): React.CSSProperties => ({
        display: 'flex',
        justifyContent: isSender ? 'flex-end' : 'flex-start',
        padding: '10px',
    })

    const messageBubble = (isSender: boolean): React.CSSProperties => ({
        maxWidth: '90%',
        minWidth: '90%',
        padding: '10px',
        borderRadius: '10px',
        backgroundColor: isSender ? '#DCF8C6' : '#FFF',
        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        position: 'relative',
    })

    const senderNameStyle: React.CSSProperties = {
        fontSize: '0.85em',
        color: '#555',
        marginBottom: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }

    const editDeleteBtnStyle: React.CSSProperties = {
        fontSize: 20,
        border: 'none',
        background: 'none',
        cursor: 'pointer',
    }

    const messageTextStyle: React.CSSProperties = {
        fontSize: '1em',
        color: '#000',
    }

    const senderIdStyle: React.CSSProperties = {
        fontSize: '0.75em',
        color: '#aaa',
        marginTop: '5px',
        textAlign: 'right' as 'right',
    }

    return (
        <TopBarLayout
            title={typeToString(oneRequest?.type ?? 'Заявка от сотрудника')}
            withGoBack
            postfix={
                <Popconfirm
                    title="Закрыть заявку"
                    description="Вы уверены, что хотите закрыть заявку?"
                    okText="Закрыть"
                    cancelText="Отменить"
                    onConfirm={onDeleteRequest}
                >
                    <Button danger loading={deleteRequestLoading}>
                        Закрыть заявку
                    </Button>
                </Popconfirm>
            }
        >
            <EditModal />

            <StyledBox>
                {!getOneRequestLoading && oneRequest ? (
                    <>
                        <div>
                            {oneRequest.photo_filename && (
                                <Form>
                                    <div
                                        style={{
                                            width: '250px',
                                            marginBottom: '24px',
                                        }}
                                    >
                                        <div>
                                            <img
                                                src={`https://api.startups-dna.com/images/${oneRequest.photo_filename}`}
                                                alt="Request"
                                                style={{
                                                    width: '100%',
                                                    maxHeight: '500px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={openModal}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )}

                            {!oneRequest.photo_filename && (
                                <div
                                    style={{
                                        width: '250px',
                                        marginBottom: '24px',
                                    }}
                                >
                                    <img
                                        src={`https://placehold.co/600x400?text=Нет+фото`}
                                        alt="Request"
                                        style={{
                                            width: '100%',
                                            maxHeight: '500px',
                                        }}
                                    />
                                </div>
                            )}

                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                contentLabel="Image Modal"
                                style={{
                                    content: {
                                        top: '50%',
                                        left: '50%',
                                        right: 'auto',
                                        bottom: 'auto',
                                        marginRight: '-50%',
                                        transform: 'translate(-50%, -50%)',
                                    },
                                }}
                            >
                                <img
                                    src={`https://api.startups-dna.com/images/${oneRequest.photo_filename}`}
                                    alt="Request"
                                    style={{ width: '100%', maxHeight: '80vh' }}
                                />
                                <Button
                                    onClick={closeModal}
                                    style={{
                                        display: 'block',
                                        marginTop: '10px',
                                    }}
                                >
                                    Закрыть
                                </Button>
                            </Modal>
                        </div>

                        <Form
                            form={form}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={onUpdateRequest}
                            autoComplete="off"
                            layout="vertical"
                            style={{ width: '500px', margin: '0 auto' }}
                            initialValues={{
                                ...oneRequest,
                            }}
                        >
                            <div
                                style={{ width: '500px', marginBottom: '8px' }}
                            >
                                {/* <div
                                    style={{ width: '300px', fontSize: '16px' }}
                                >
                                    {typeToString(oneRequest.type ?? '')}
                                </div> */}
                            </div>

                            <div
                                style={{ width: '500px', marginBottom: '24px' }}
                            >
                                {chat({ oneRequest })}
                            </div>

                            <Form.Item
                                label="Сообщение"
                                name="description"
                                key="description"
                                rules={[
                                    {
                                        required: status === 'rejected',
                                        message: 'Сообщение обязательно!',
                                    },
                                ]}
                            >
                                <Input
                                    style={{ width: '500px' }}
                                    placeholder="Введите сообщение"
                                    value={description}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Статус"
                                name="status"
                                key="status"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Статус обязательное поле!',
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: '500px' }}
                                    placeholder="Выберите статус"
                                    onChange={(value) => setStatus(value)}
                                >
                                    <Select.Option value="new">
                                        Новая
                                    </Select.Option>
                                    <Select.Option value="in_progress">
                                        В работе
                                    </Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={isSaveButtonDisabled()}
                                    loading={updateRequestLoading}
                                >
                                    Сохранить
                                </Button>
                            </Form.Item>
                        </Form>

                        <Form>
                            <div
                                style={{ width: '300px', marginBottom: '24px' }}
                            >
                                <b>Дата</b>
                                <div style={{ width: '300px' }}>
                                    {dateStringToLocale(oneRequest.createdat)}
                                </div>
                            </div>

                            <div style={{ marginBottom: '24px' }}>
                                <b>Сотрудник</b>
                                <Row align="top">
                                    {oneRequest.staff.photo && (
                                        <img
                                            src={`https://api.startups-dna.com/images/${oneRequest.staff.photo}`}
                                            alt="Request"
                                            style={{
                                                width: '100%',
                                                maxHeight: '30px',
                                                maxWidth: '30px',
                                                marginRight: '8px',
                                                marginTop: '8px',
                                            }}
                                        />
                                    )}
                                    <div
                                        style={{
                                            width: '300px',
                                            marginTop: '8px',
                                        }}
                                    >
                                        {oneRequest.staff.full_name}
                                    </div>
                                </Row>
                            </div>
                        </Form>
                    </>
                ) : (
                    <SpinWrapper>
                        <Spin />
                    </SpinWrapper>
                )}
            </StyledBox>
        </TopBarLayout>
    )
}

export default OneRequest
